<template>
    <div class="home">
        <div class="home_date">
            <h2>Save the date</h2>
            <div class="home_date_container">
                <img src="../assets/border_top.png" alt="Border café & Thé">
                <div class="home_date_container_text">
                    <CountDownComponent></CountDownComponent>
                    <p>Le <span class="date_info">26/04/2025</span> à <span class="date_info">Morigny Champigny</span>.</p>
                </div>
                <img src="../assets/border_bottom.png" alt="Border café & Thé">
            </div>
        </div>
        <div class="home_presentation">
            <div class="home_presentation_polaroids">
                <PolaroidComponent :imgAssets="{
                    id: 'pola_martigny',
                    source: require('../assets/Martigny.jpg'),
                    alt: 'Tour de Villemartin :)',
                    title: 'Le lieu',
                    description: 'Morigny Champigny'
                }" :transform="{ x: 25, y: -9, rotate: -9 }">
                </PolaroidComponent>
                <PolaroidComponent :imgAssets="{
                    id: 'pola_us',
                    source: require('../assets/Nous.jpg'),
                    alt: 'Une photo de nous :)',
                    title: 'Notre histoire',
                    description: 'Depuis le 08/07/2017'
                }" :transform="{ x: -45, y: 0, rotate: 9 }">
                </PolaroidComponent>
            </div>
            <div class="home_presentation_texts">
                <div class="home_presentation_texts_element">
                    <h3>"Oui, je le veux !"</h3>
                    <p>C'est au Sommet du Grand Paradis que nous avons décidé de sceller notre union. Depuis plus de 7
                        ans déjà, nous célébrons la vie.<br>
                        Les souvenirs se cumulent et le tourbillon de nos quotidiens nous embarque, nous entraîne, laissant s'échapper quelques précieux
                        instants.<br>
                        Nous vous proposons une pause. Un temps d'arrêt pour célébrer tous ensemble de nouveaux moments de bonheur lors de notre mariage.<br>
                    </p>
                </div>
                <div class="home_presentation_texts_element">
                    <h3>Quand ? Où ?</h3>
                    <p>Nous avons l'immense joie de vous accueillir le <span>Samedi 26 Avril 2025</span> dans un seul et
                        unique lieu à <span>L'Hotellerie Nouvelle de Villemartin (91)</span>.<br />
                        Nous avons hâte de vous retrouver pour une journée remplie d'émotions, qui nous espérons vous
                        fera autant plaisir qu'à nous.</p>
                    <br />
                </div>
            </div>
        </div>
        <div class="home_signin">
            <img src="../assets/border_top.png" alt="Border café & Thé">
            <div class="home_signin_container">
                <h2>Le déroulement de la journée</h2>
                <ButtonNavComponent text="Découvrir" url="/mariage"></ButtonNavComponent>
            </div>
            <img src="../assets/border_bottom.png" alt="Border café & Thé">
        </div>
        <div class="mariage_section">
            <img src="@/assets/divider.png" class="divider" alt="Simple divider">
        </div>
        <div class="home_chrono">
            <h2>Notre histoire</h2>
            <div class="home_chrono_arrow"></div>
            <div class="home_chrono_section">
                <PolaroidComponent :imgAssets="{
                    id: 'pola_debut',
                    source: require('../assets/our_story/debut.jpg'),
                    alt: 'Tout au debut',
                    title: 'Les Début',
                    description: '08/07/2017'
                }" :transform="{ x: 0, y: 0, rotate: -7 }">
                </PolaroidComponent>
                <div class="home_chrono_section_paragraphe">
                    <h3>Une rencontre</h3>
                    <p>C'est à Orly que nous nous sommes rencontré, chacun engagé dans nos vies respectives, il nous a
                        parut impossible de ne pas s'ouvrir l'un à l'autre.
                        Madame dans le monde du thé et monsieur dans celui du café, c'est tout naturellement que
                        l'alchimie de deux être opposés c'est mélangé pour donner naissance à ce que nous sommes
                        aujourd'hui.</p>
                </div>
            </div>
            <div class="home_chrono_section">
                <PolaroidComponent :imgAssets="{
                    id: 'pola_evry',
                    source: require('../assets/our_story/evry.jpg'),
                    alt: 'Polaroid Evry',
                    title: 'Evry',
                    description: '02/2018'
                }" :transform="{ x: 0, y: 0, rotate: 4 }">
                </PolaroidComponent>
                <div class="home_chrono_section_paragraphe">
                    <h3>Un "Chez nous"</h3>
                    <p>Très vite madame s'installe dans la colocation fraternelle. C'est la vie ensemble, on se découvre
                        et continue de se plaire. Les jours passent est cela se fait une évidence. Nous nous aimons.</p>
                </div>
            </div>
            <div class="home_chrono_section">
                <PolaroidComponent :imgAssets="{
                    id: 'pola_islande',
                    source: require('../assets/our_story/island.jpg'),
                    alt: 'Polaroid islande',
                    title: 'Islande',
                    description: '09/2019'
                }" :transform="{ x: 0, y: 0, rotate: -3 }">
                </PolaroidComponent>
                <div class="home_chrono_section_paragraphe">
                    <h3>Premier Trek</h3>
                    <p>Première aventure ! C'est plein d'enthousiasme que madame découvre le plaisir de la marche et du
                        bivouac. Une incroyable semaine accompagnés d'incroyables copains. Pendant laquelle nous eu avons la
                        certitude d'une chose. C'est ensemble que nous avancerons.</p>
                </div>
            </div>
            <div class="home_chrono_section">
                <PolaroidComponent :imgAssets="{
                    id: 'pola_auvergne',
                    source: require('../assets/our_story/auvergne.jpg'),
                    alt: 'Une photo de nous :)',
                    title: 'Auvergne',
                    description: '05/2020'
                }" :transform="{ x: 0, y: 0, rotate: 7 }">
                </PolaroidComponent>
                <div class="home_chrono_section_paragraphe">
                    <h3>Nouvelle vie</h3>
                    <p>La vie nous pousse parfois à faire des choix et c'est décidé que nous sommes partis faire un bout
                        de chemin en Auvergne. L'avantage de la distance c'est qu'elle permet de nous recentrer sur
                        l'essentiel : Nous.</p>
                </div>
            </div>
            <div class="home_chrono_section">
                <PolaroidComponent :imgAssets="{
                    id: 'pola_today',
                    source: require('../assets/our_story/today.jpg'),
                    alt: 'Une photo de nous :)',
                    title: 'Toi et moi',
                    description: '26/04/2025'
                }" :transform="{ x: 0, y: 0, rotate: 10 }">
                </PolaroidComponent>
                <div class="home_chrono_section_paragraphe">
                    <h3>Aujourd'hui</h3>
                    <p> Dejà plus de 7 années à la date à laquelle vous voyez ce faire-part. Jusqu'où irons-nous ? Le plus loin possible, nous espérons.</p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import CountDownComponent from "@/components/CountDownComponent.vue";
import PolaroidComponent from '@/components/PolaroidComponent.vue';
import ButtonNavComponent from "@/components/ButtonNavComponent.vue"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: "HomeView",
    components: {
        CountDownComponent,
        PolaroidComponent,
        ButtonNavComponent
    },
    mounted() {
        gsap.from(".home_date, .home_signin", {
            opacity: 0,
            y: 50,
            duration: 1,
            stagger: 0.2
        });

        // Animation pour les polaroids
        gsap.from(".home_presentation_polaroids > *", {
            opacity: 0,
            y: 50,
            rotate: 20,
            duration: 1,
            stagger: 0.3,
            ease: "back.out(1.7)"
        });

        const chronos = document.querySelectorAll(".home_chrono_section")

        chronos.forEach((section, index) => {
            gsap.from(section, {
                opacity: 0,
                y: 50,
                duration: 1,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
                delay: index * 0.2
            });
        });

    }
}

</script>

<style lang="scss" scoped>


.home {
    position: relative;
    z-index: 0;
    @include View;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_date {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3em;

        &_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &_text {
                @include Cadre;
            }

            img {
                width: 80%;
                max-width: 500px;
            }
        }

        p {
            margin: 20px 10px;
            text-align: center;
            font-size: 1.3em;

            span {
                color: $GreenShade1;
                font-size: 1em;
            }
        }
    }

    &_presentation {
        margin: 25px 0;
        display: flex;
        flex-direction: column;

        &_polaroids {
            display: flex;
            justify-content: center;
        }

        &_texts {
            margin-top: 2em;
            text-align: center;

            &_element {
                margin-bottom: 1em;

                p {
                    text-align: center;
                    width: 80%;
                    margin: auto;
                }

                span {
                    color: $GreenShade1;
                    font-style: bold;
                    font-size: 1rem;
                }
            }
        }
    }

    &_signin {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 95%;
        max-width: 700px;

        &_container {
            @include Cadre;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }

        img {
            width: 80%;
            max-width: 500px;
        }

        h2 {
            margin: 0 0 0.5em 0;
            font-size: 2.5em;
            text-align: center;
        }
    }

    &_chrono {
        width: 90%;
        margin: 25px auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_arrow {
            margin-top: 12vh;
            width: 10%;
            background-color: $BrownShade3;
            height: 60%;
            position: absolute;
            z-index: 50;
            clip-path: polygon(0 85%, 25% 85%, 25% 0, 75% 0, 75% 85%, 100% 85%, 50% 100%);
        }

        &_section {
            position: relative;
            z-index: 51;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 25px auto;

            &_paragraphe {
                text-align: center;
                margin: 10px 0;
                width: 80%;
            }
        }

        h2 {
            position: relative;
            z-index: 51;
        }
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.home::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.home {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
</style>