<template>
    <div class="header">
        <h1>Melinda & Jeremy</h1>
        <div v-if="!isMobile" class="header_desktop">
            <nav>
                <!-- Navigation items for desktop view -->
                <router-link :class="{ active: isActive('/') }" to="/">Nous</router-link>
                <router-link :class="{ active: isActive('/mariage') }" to="/mariage">Le Mariage</router-link>
                <router-link :class="{ active: isActive('/notabene') }" to="/notabene">Nota Bene</router-link>
                <router-link :class="{ active: isActive('/sayyes') }" to="/sayyes">Dire oui</router-link>
            </nav>
        </div>
        <div v-if="isMobile" class="header_mobile">
            <div ref="background" class="header_mobile_background"></div>
            <ToogleComponent @toggle="toggleMobileMenu" ref="toogle"></ToogleComponent>
            <nav ref="mobileNav" class="header_mobile_nav" v-show="isMobileMenuOpen">
                <router-link :class="{ active: isActive('/') }" @click="closeMobileMenu" to="/">Nous</router-link>
                <router-link :class="{ active: isActive('/mariage') }" @click="closeMobileMenu" to="/mariage">Le
                    Mariage</router-link>
                <router-link :class="{ active: isActive('/notabene') }" @click="closeMobileMenu" to="/notabene">Nota
                    Bene</router-link>
                <router-link :class="{ active: isActive('/sayyes') }" @click="closeMobileMenu" to="/sayyes">Dire oui</router-link>
            </nav>
            <div class="header_mobile_divider">
                <img src="@/assets/divider.png" alt="Simple divider">
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import ToogleComponent from "@/components/ToogleComponent.vue";

export default {
    name: "HeaderComponent",
    components: {
        ToogleComponent,
    },
    data() {
        return {
            isMobile: false,
            isMobileMenuOpen: false,
            currentRoute: this.$route.path,
        };
    },
    methods: {
        toggleMobileMenu() {
            if (!this.isMobileMenuOpen) {
                this.expandBackground();
                this.animateLinksIn();
            } else {
                this.collapseBackground();
                this.animateLinksOut();
            }
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 768;
        },
        expandBackground() {
            gsap.to(this.$refs.background, { duration: 0.3, height: '100vh' });
        },
        collapseBackground() {
            gsap.to(this.$refs.background, { duration: 0.3, height: '0' });
        },
        animateLinksIn() {
            gsap.fromTo(this.$refs.mobileNav,
                { opacity: 0, y: -20 },
                { opacity: 1, y: 0, stagger: 0.1, duration: 0.5, delay: 0.3, color: "#7F5539" });
        },
        animateLinksOut() {
            gsap.to(this.$refs.mobileNav,
                { opacity: 0, duration: 0.5 });
        },
        isActive(route) {
            return this.currentRoute === route;
        },
        closeMobileMenu() {
            this.isMobileMenuOpen = false;
            this.collapseBackground();
            this.animateLinksOut();
            this.$refs.toogle.resetToggle();  // Call method on ToogleComponent to reset its state
        }
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    watch: {
        $route(to) {
            this.currentRoute = to.path;
        }
    },
};
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 50;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    &_mobile {

        display: flex;
        flex-direction: column;
        align-items: center;

        &_background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            background-color: $FifthColor;
            z-index: 51;
            transition: height 0.5s ease;
        }

        &_divider {
            position: relative;
            z-index: 52;
            width: 80%;
            margin: auto;
            transition: 0.2s;

            img {
                width: 100%;
                max-width: 350px;
            }
        }

        &_nav {
            position: relative;
            z-index: 52;
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: 0;
            /* Initial state for GSAP animation */
            margin-top: 20px;
        }
    }
    &_desktop {
        width: 80%;
        display: flex;
        nav {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }
    }
    h1 {
        position: relative;
        z-index: 52;
        margin:  15px auto;
    }
}

a {
    margin: 10px 0;
    text-decoration: none;
    color: $FirstColor;
    transition: 0.5s;

    &:hover {
        color: $GreenShade1;
    }
    &.active {
        font-weight: bold;
        color: $GreenShade1;
    }
}
</style>