<template>
    <div 
        class="polaroid" 
        :id="imgAssets.id" 
        :class="{ hovered: isHovered }" 
        @mouseover="isHovered = true" 
        @mouseleave="isHovered = false"
    >
        <img :src="imgAssets.source" :alt="imgAssets.alt">
        <div class="polaroid_text">
            <h3>{{ imgAssets.title }}</h3>
            <p>{{ imgAssets.description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'polaroidComponent',
    props: {
        imgAssets: Object,
        transform: Object,
    },
    data() {
        return {
            isHovered: false
        }
    },
    methods: {
        makeTransform(transform) {
            const div = document.querySelector(`#${this.imgAssets.id}`)
            let transform_instruction_x = ""
            let transform_instruction_y = ""
            let rotate_instruction = ""
            if(transform.rotate) {
                rotate_instruction = `rotate(${transform.rotate}deg)`
            }
            if(transform.x) {
                transform_instruction_x = `translateX(${transform.x}px) `
            }
            if (transform.y) {
                transform_instruction_y = `translateY(${transform.y}px)`
            }
            div.style.transform = transform_instruction_x + transform_instruction_y + rotate_instruction
        }
    },
    mounted() {
        if (this.transform) {
           this.makeTransform(this.transform)
        }
    }
}
</script>

<style lang="scss" scoped>
.polaroid {
    position: relative;
    z-index: 48;
    background-color: white;
    box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
    transition: z-index 0.3s;

    &_text {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        h3 {
          margin: none;  
        }
    }

    &.hovered {
        z-index: 49;
    }
}

.polaroid > img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    image-rendering: auto;
}

@media screen and (min-width: 2000px) {
    .polaroid {
        width: 450px;
        height: 550px;
        padding: 1.5rem;
        h3 {
            font-size: 3.5em;
        }
        p {
            font-size: 1.5em;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1999px) {
    .polaroid {
        width: 200px;
        height: 250px;
        padding: 0.8rem;
        h3 {
            font-size: 1.5em;
            margin: none;
        }
        p {
            font-size: 0.7rem;
            margin: 3px;
        }
    }
}

@media screen and (max-width: 1023px) {
    .polaroid {
        width: 200px;
        height: 250px;
        padding: 0.6rem;
        h3 {
            font-size: 1.9rem;
        }
        p {
            font-size: 0.7rem;
        }
    }
}
</style>
