<template>
    <div class="mariage">
        <div class="mariage_section">
            <h2>Cup of Love</h2>
            <div class="mariage_section_part">
                <div class="mariage_section_part_container">
                    <img src="../assets/filigrame_cup.png" alt="Logo cafe-the">
                </div>
                <h3>La base</h3>
                <p>Notre histoire a débuté de manière aussi douce qu'inattendue. Réunis lors des pauses à l'aéroport
                    d'Orly accompagnées de tasses contenant les breuvages qui nous ont rassemblé.</p>
                <br />
                <br />
                <p>C'est en repensant à ces moments que nous avons naturellement choisi le thème de notre mariage
                    :<br /> <b><span>"Le Café et le Thé"</span></b>.</p>
            </div>
            <div class="mariage_section_part">
                <h3>Code couleur</h3>
                <img src="@/assets/ceremonies/gradient.png" alt="Gradient code couleurs" class="gradient">
                <p>Vous l'aurez compris, nous tournons notre théme autour de deux couleurs principales <b>le Marron</b>
                    et <b>le
                        Vert</b>.</p>
                <p>Toute nuance acceptée, vous pouvez aussi, si vous le souhaitez, rester plus neutre. Le Gris étant
                    accepté cependant nous vous prions d'éviter le Noir et le Blanc svp (Obvious?) </p>
            </div>
        </div>
        <div class="mariage_section">
            <img src="@/assets/divider.png" class="divider" alt="Simple divider">
        </div>
        <div class="mariage_section">
            <h2>Moments Forts</h2>
            <p>De beaux moments à partager ensemble.</p>
            <div class="mariage_section_part">
                <h3>Cérémonie</h3>
                <PolaroidComponent :imgAssets="{
                    id: 'pola_laique',
                    source: require('../assets/ceremonies/laique.jpg'),
                    alt: 'Tour de Villemartin :)',
                    title: '14h - 15h',
                    description: 'Je dis oui'
                }" :transform="{ x: 0, y: 0, rotate: 5 }">
                </PolaroidComponent>
                <h4>Où & quand</h4>
                <p>Rendez-vous à <span>14h00</span> dans le Jardin de l'Hôtellerie Nouvelle Villemartin.</p>
                <h4>Une promesse</h4>
                <p>C'est le moment que nous attendions avec tant d'impatience. Nous sommes extrêmement heureux de
                    partager
                    cette promesse d'amour, entourés de nos plus proches. La cérémonie laïque sera un moment de joie,
                    d'émotion et de sincérité, où chaque parole et chaque geste refléteront notre engagement mutuel.</p>
                <br />
                <p>Nous serions honorés si vous souhaitiez contribuer à ce moment en proposant un discours, une anecdote
                    ou
                    toute autre intervention qui vous tient à cœur. Votre participation rendra cette journée encore plus
                    spéciale et mémorable.</p>
            </div>
            <div class="mariage_section_part">
                <h3>Jeux</h3>
                <PolaroidComponent :imgAssets="{
                    id: 'pola_jeux',
                    source: require('../assets/ceremonies/jeux.jpg'),
                    alt: 'Photo partie les jeux',
                    title: '16h - 18h30',
                    description: 'Team building ? :O'
                }" :transform="{ x: 0, y: 0, rotate: -7 }">
                </PolaroidComponent>
                <h4>Où & quand</h4>
                <p>Rendez-vous à <span>16h00</span> dans le Jardin de l'Hôtellerie Nouvelle Villemartin.</p>
                <h4>Une seul équipe</h4>
                <p>Après les moments d’émotion et de promesse, il est temps de nous réunir autour de rires et de
                    complicité. Pour nous, ces jeux sont bien plus qu'un simple divertissement : ils symbolisent l'union
                    de nos familles et amis dans un esprit de partage et de bonne humeur.</p>
                <br />
                <p>Les jeux que nous avons choisis sont pensés pour briser la glace et créer des liens. Ici, tout le
                    monde est gagnant, car l’objectif est de partager des rires et des sourires, et de faire grandir
                    cette nouvelle grande famille que nous formons ensemble.
                </p>
                <br />
                <p>Nous espérons que ces moments de légèreté et d'amusement resteront gravés dans vos mémoires, tout
                    comme le lien unique que nous tissons avec chacun d'entre vous. Ensemble, dans la joie et l’unité,
                    nous célébrerons cette journée inoubliable.

                </p>
                <br />
                <p>Nous sommes ravis de pouvoir partager cette coutume précieuse avec vous !</p>
            </div>
            <div class="mariage_section_part">
                <h3>Célébration</h3>
                <PolaroidComponent :imgAssets="{
                    id: 'pola_soiree',
                    source: require('../assets/ceremonies/celebration.jpg'),
                    alt: 'Photo partie celebration',
                    title: '19h - 05h',
                    description: 'Il est temps de festoyer !'
                }" :transform="{ x: 0, y: 0, rotate: 5 }">
                </PolaroidComponent>
                <h4>Où & quand</h4>
                <p>Rendez-vous à <span>19h00</span> sur la Terasse de l'Hôtellerie Nouvelle Villemartin.</p>
                <h4>La célébration</h4>
                <p>
                    Après les émotions de la cérémonie laïque et du Soukhouan laotien, il est temps de passer à la fête
                    !
                    Nous vous invitons à rejoindre la soirée où nous célébrerons notre union dans la joie et la bonne
                    humeur.
                </p>
                <br>
                <p>
                    Préparez-vous à déguster un délicieux repas, spécialement réfléchi pour cette occasion, qui saura
                    ravir
                    vos papilles. Ensuite, place à la fête : nous danserons, rirons et profiterons de chaque instant
                    ensemble.
                </p>
            </div>
        </div>
        <div class="mariage_section_signin mariage_section">
            <img src="../assets/border_top.png" alt="Border café & Thé">
            <div class="mariage_section_signin_container">
                <h2>Répondre à notre invitation</h2>
                <ButtonNavComponent text="Dire oui !" url="/sayyes"></ButtonNavComponent>
            </div>
            <img src="../assets/border_bottom.png" alt="Border café & Thé">
        </div>
        <div class="mariage_section">
            <img src="@/assets/divider.png" class="divider" alt="Simple divider">
        </div>
        <div class="mariage_section">
            <h2>Les événements</h2>
            <p>Rien ne vaut la musique ou les jeux pour nous rassembler</p>
            <div class="mariage_section_part">
                <h3>Set DJ</h3>
                <p>WIP - Soyez patient, demain, ça sera peut-être à jour</p>
            </div>
            <div class="mariage_section_part">
                <h3>Livre d'Or</h3>
                <p>WIP - Souvenirs en construction</p>
            </div>
        </div>
    </div>
</template>

<script>

import PolaroidComponent from "@/components/PolaroidComponent.vue"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ButtonNavComponent from "@/components/ButtonNavComponent.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: "Le_Mariage",
    components: {
        PolaroidComponent,
        ButtonNavComponent
    },
    data() {
        return {

        }
    },
    mounted() {
        const sections = document.querySelectorAll(".mariage_section");

        sections.forEach((section, index) => {
            gsap.from(section, {
                opacity: 0,
                y: 50,
                duration: 1,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
                delay: index * 0.2
            });
        });

        const parts = document.querySelectorAll(".mariage_section_part")

        parts.forEach((section, index) => {
            gsap.from(section, {
                opacity: 0,
                y: 50,
                duration: 1,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
                delay: index * 0.2
            });
        });
    }
}
</script>

<style lang="scss" scoped>
.mariage {
    @include View;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
        color: $GreenShade1;
        font-size: 1em;
    }

    &_section {
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_part {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;

            &_container {
                img {
                    margin-bottom: 30px;
                    width: 300px;
                }
            }

            .gradient {
                width: 100%;
                max-width: 600px;
                margin: auto;
                border-radius: 15px;
                border: 0.5px solid $BrownShade1;
                margin-bottom: 25px;
            }
        }

        &_signin {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            width: 95%;
            max-width: 700px;

            &_container {
                @include Cadre;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
            }

            img {
                width: 80%;
                max-width: 500px;
            }

            h2 {
                margin: 0 0 0.5em 0;
                text-align: center;
                font-size: 2.5em;
            }
        }

        h3 {
            margin: 25px;
        }

        h4 {
            margin: 25px auto;
        }
    }
}
</style>