<template>
    <div class="say_yes">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSf5mdx_WEvPVRisQkzWFCqyehrHjbLNyxMObSl0sS2tRhhCxg/viewform?embedded=true"
          frameborder="0" marginheight="0" marginwidth="0" class="say_yes_form">Chargement…</iframe>
    </div>
  </template>
  
  <style lang="scss" scoped>
  .say_yes {
    display: flex;
    justify-content: center;
    height: 100vh;
    margin-bottom: 10vh;
    .say_yes_form {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
  </style>
  