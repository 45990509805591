import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import MariageView from '@/views/MariageView.vue'
import NotaBeneView from '@/views/NotaBeneView.vue'
import SayYesView from '@/views/SayYesView.vue'
import AuthView from '@/views/AuthView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/mariage',
    name: "Le_Mariage",
    component: MariageView
  },
  {
    path: "/notabene",
    name: "Nota_Bene",
    component: NotaBeneView
  },
  {
    path: "/sayyes",
    name: "Say_yes",
    component: SayYesView
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthView
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'home' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
})

// Gardien global pour l'authentification
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('auth');
  if (to.name !== 'Auth' && isAuthenticated != process.env.APP_KEY) {
    next({ name: 'Auth' });
  } else {
    next(); 
  }
});

export default router
