<template>
  <button class="toggle-button" @click="toggleMenu">
    <div ref="bar1" class="bar"></div>
    <div ref="bar2" class="bar"></div>
    <div ref="bar3" class="bar"></div>
  </button>
</template>

<script>
import { gsap } from "gsap";
  
export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        this.animateToArrow();
      } else {
        this.animateToBars();
      }
      this.$emit('toggle');
    },
    animateToArrow() {
      gsap.to(this.$refs.bar1, { duration: 0.5, rotate: -45, y: 9, backgroundColor: "#6D9362" });
      gsap.to(this.$refs.bar2, { duration: 0.5, opacity: 0 });
      gsap.to(this.$refs.bar3, { duration: 0.5, rotate: 45, y: -9, backgroundColor: "#6D9362" });
    },
    animateToBars() {
      gsap.to(this.$refs.bar1, { duration: 0.5, rotate: 0, y: 0, backgroundColor: "#7F5539" });
      gsap.to(this.$refs.bar2, { duration: 0.5, opacity: 1, backgroundColor: "#7F5539" });
      gsap.to(this.$refs.bar3, { duration: 0.5, rotate: 0, y: 0, backgroundColor: "#7F5539" });
    },
    resetToggle() {
      this.isMenuOpen = false;
      this.animateToBars();
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-button {
  position:relative;
  z-index: 51;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  width: 40px;
  position: relative;
  .bar {
      background-color: $FirstColor;
      height: 3px;
      margin: 1px 0;
      width: 100%;
      border-radius: 2px;
  }
}

</style>
