<template>
  <div class="app">
    <div class="background_font">
      <img src="./assets/background/back_background.png" alt="back of background">
    </div>
    <HeaderComponent ref="headerComponent"></HeaderComponent>
    <div class="main-content" ref="mainContent">
      <router-view />
    </div>
    <div class="background_face" ref="backgroundFront">
      <img src="./assets/background/front_background.png" alt="front of background">
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: 'App',
  components: {
    HeaderComponent
  },
  mounted() {
    // Appeler adjustMainContentMargin après que tous les éléments soient montés
    this.$nextTick(() => {
      this.adjustMainContentMargin();
      this.adjustBackgroundSize();
      window.addEventListener('resize', this.adjustMainContentMargin);
      window.addEventListener('resize', this.adjustBackgroundSize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustMainContentMargin);
    window.removeEventListener('resize', this.adjustBackgroundSize);
  },
  methods: {
    adjustMainContentMargin() {
      const headerHeight = this.$refs.headerComponent.$el.offsetHeight;
      const backgroundFrontHeight = this.$refs.backgroundFront.offsetHeight;
      this.$refs.mainContent.style.marginTop = `${headerHeight}px`;
      this.$refs.mainContent.style.marginBottom = `${backgroundFrontHeight}px`;
    },
    adjustBackgroundSize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.app {
  .main-content {
    min-height: calc(var(--vh, 1vh) * 100);
    transition: margin-top 0.3s ease, margin-bottom 0.3s ease; /* Smooth transition for margin changes */
  }
}

.background_font {
  background-attachment: fixed;
  position: fixed;
  z-index: -99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
    position: fixed;
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.background_face {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  
  img {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 15vh;
    max-height: 30vh;
  }
}

.divider {
        max-width: 300px;
        margin: 1.5em auto;
}

/* Hide scrollbar for webkit browsers */
.app::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.app {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
