<template>
    <div class="countdown">
        <div class="countdown_item" v-show="timeRemaining.days != 0">
            <span class="number">{{ timeRemaining.days }}</span>
            <div class="countdown_item_element">
                <span class="label">Jour</span><span class="label" v-show="timeRemaining.days != 1">s</span>
            </div>
        </div>
        <div class="countdown_item" v-show="timeRemaining.hours != 0">
            <span class="number">{{ timeRemaining.hours }}</span>
            <div class="countdown_item_element">
                <span class="label">Heure</span><span class="label" v-show="timeRemaining.hours != 1">s</span>
            </div>
        </div>
        <div class="countdown_item" v-show="timeRemaining.minutes != 0">
            <span class="number">{{ timeRemaining.minutes }}</span>
            <div class="countdown_item_element">
                <span class="label">Minute</span><span class="label" v-show="timeRemaining.minutes != 1">s</span>
            </div>
        </div>
        <div class="countdown_item" v-show="timeRemaining.seconds != 0">
            <span class="number">{{ timeRemaining.seconds }}</span>
            <div class="countdown_item_element">
                <span class="label">Seconde</span><span class="label" v-show="timeRemaining.seconds != 1">s</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timeRemaining: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            },
            targetDate: new Date("2025-04-26T00:00:00")
        };
    },
    mounted() {
        this.updateCountdown();
        this.timer = setInterval(this.updateCountdown, 1000);
    },
    beforeUnmount() {
        clearInterval(this.timer);
    },
    methods: {
        updateCountdown() {
            const now = new Date();
            const difference = (this.targetDate - now) / 1000;
            if (difference > 0) {
                const days = Math.floor(difference / (60 * 60 * 24));
                const hours = Math.floor((difference % (60 * 60 * 24)) / (60 * 60));
                const minutes = Math.floor((difference % (60 * 60)) / 60);
                const seconds = Math.floor(difference % 60);

                this.timeRemaining = { days, hours, minutes, seconds };
            } else {
                this.timeRemaining = { days: 0, hours: 0, minutes: 0, seconds: 0 };
                clearInterval(this.timer);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 2rem;
    color: $FirstColor;

    &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
        &_element {
            display: flex;
            .label {
                color: $GreenShade1;
            }
        }
        .number {
            font-weight: bold;
        }
    }
}

@media screen and (min-width: 2000px) {
    span {
        font-size: 1.3em;
    }
    .label {
                color: $GreenShade1;
                font-size: 1.1em;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1999px) {
    span {
        font-size: 0.9em;
    }
    .label {
                color: $GreenShade1;
                font-size: 0.8em;
    }
}

@media screen and (max-width: 1023px) {
    span {
        font-size: 0.7em;
    }
    .label {
                color: $GreenShade1;
                font-size: 0.5em;
            }
}

</style>